<template>
  <v-container class="full-height">
    <div class="d-flex justify-center align-center full-height">
      <v-card min-width="50%">
        <v-card-title>
          <span class="mainTitle" style="font-size: 20px"> Forgot Password </span>
        </v-card-title>
        <v-card-text>
          <label-with-text-field item-field-name="Email Address" v-model="userEmail" />
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn @click="back">Back</v-btn>
          <v-btn @click="submit" color="primary">Submit</v-btn>
        </v-card-actions>
      </v-card>
    </div>
  </v-container>
</template>

<script>
import api from '@/api';
import { forgetPassword } from '@/api/auth/sso';
import LabelWithTextField from '../../components/customFormLabel/labelWithTextField';

export default {
  components: { LabelWithTextField },
  data() {
    return {
      userEmail: null,
    };
  },
  methods: {
    async submit() {
      try {
        const result = await forgetPassword(api, { email: this.userEmail });
        if (result.data === true) {
          await this.$store.dispatch('alert/updateAlertMessage', {
            msg: 'An email has been sent to your email address.',
            type: 'success',
            color: 'success',
          });
        }
      } catch (err) {
        console.log(err);
        alert('action failed');
      }
    },
    back() {
      this.$router.go(-1);
    },
  },
};
</script>
